.sec-title3,
.sec-title {
  text-transform: uppercase;
  line-height: 1.35;
  margin: -0.25em 0 18px 0;
}

.sec-title2 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: -0.3em 0 22px 0;
}

.sec-title3 {
  text-transform: capitalize;
}

.sec-subtitle {
  color: $theme-color;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-family: $title-font;
  margin: -0.25em 0 23px 0;
  display: block;

  >i {
    margin-right: 15px;
  }
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 17px);
}

.sec-btns {
  padding: 13px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  
  i.fa-arrow-left {
    margin-left: 0;
    margin-right: 10px;
  }
}

.sec-btns2 {
  margin-bottom: calc(var(--section-title-space) - 5px);
}


.sec-line-wrap {
  display: flex;
  gap: 25px;
  align-items: center;
  margin: 0 0 80px 0;

  .sec-title2 {
    margin: -0.3em 0 -0.4em 0;
  }
}

.sec-line {
  background-color: $theme-color;
  flex: 1;
  height: 2px;
}


@include md {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 36px;
  }

  .sec-line-wrap {
    margin: 0 0 60px 0;
  }

  .title-area {
    margin-bottom: 40px;
  }
}


@include sm {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 30px;
  }

  .sec-subtitle {
    font-size: 16px;
  }

  .sec-line-wrap {
    margin: 0 0 40px 0;
    gap: 10px;
  }
}